<template>
	<v-card outlined :color="`red lighten-5`" class="h-100 d-flex flex-column overflow-hidden">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2" color="red">mdi-chart-areaspline</v-icon>
			<div class="my-auto fw-600 red--text text-uppercase fs-18">Closed Lead Source</div>
			<v-spacer></v-spacer>
			<template v-if="['admin', 'manager', 'master'].includes(checkAdminOrManager())">
				<v-menu left bottom offset-y :max-width="200" :max-height="300" content-class="z-index-11">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							height="35"
							min-height="35"
							color="red lighten-4"
							class="mr-3"
							depressed
							v-bind="attrs"
							v-on="on"
						>
							<span class="fs-16 text-capitalize text-truncate" style="width: 120px">{{
								dActiveClosedLeadUser.display_name
							}}</span>
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in usersList"
							:key="index"
							@click="filterByUser(item)"
							:class="[
								'py-1',
								{
									'grey lighten-3': JSON.stringify(dActiveClosedLeadUser) == JSON.stringify(item),
								},
							]"
						>
							<v-list-item-avatar size="30" class="my-1 mr-2">
								<v-img
									:src="item.profile_img ? item.profile_img : $assetURL(`media/users/blank.png`)"
								></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title style="min-width: auto">{{ item.display_name }}</v-list-item-title>
								<v-list-item-subtitle class="mb-1 text-uppercase" v-if="item.total_project">
									<span class="fw-700">{{ item.total_project }}</span> project</v-list-item-subtitle
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						height="35"
						min-height="35"
						color="red lighten-4"
						class="mr-3"
						depressed
						v-bind="attrs"
						v-on="on"
					>
						<span class="fs-16 text-capitalize px-1">{{ dActiveClosedLeadType.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterProductTypeList"
						:key="index"
						@click="filterByType(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveClosedLeadType.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="red lighten-4" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-1">{{ dActiveClosedLeadDuration.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterStatusList"
						:key="index"
						@click="filterByDuration(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveClosedLeadDuration.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<v-card-text class="flex-grow-1 position-relative">
			<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
			<v-img class="sale-symbol" :src="$assetURL('media/bg/sales-4.png')"></v-img>
			<v-img class="sale2-symbol" :src="$assetURL('media/bg/sales-5.png')"></v-img>
			<div class="d-flex flex-wrap align-center justify-center h-100">
				<div style="min-width: 250px; max-width: 350px" class="rounded pa-5">
					<h4 class="text-red">
						Total sales made by <span class="text-lowercase">{{ dActiveClosedLeadDuration.text }}</span>
					</h4>
					<div class="text-right d-flex justify-end mt-3">
						<div class="mr-10">
							<h1 class="green--text font-weight-bolder mb-0" style="font-size: 3rem">
								<template v-if="pageLoading">
									<v-progress-circular indeterminate size="20" width="3" color="green"></v-progress-circular>
								</template>
								<template v-else><span style="font-size: 3.4rem"> $</span> {{ getTotalCost }} </template>
							</h1>
						</div>
						<div class="total-lead-count">
							<h2 class="blue--text font-weight-bolder mb-0" style="font-size: 2rem">
								<template v-if="pageLoading">
									<v-progress-circular indeterminate size="20" width="3" color="blue"></v-progress-circular>
								</template>
								<template v-else> {{ getTotalLead }} </template>
							</h2>
							<p>leads</p>
						</div>
					</div>
				</div>
				<div class="d-flex flex-wrap align-center justify-center mt-5">
					<div class="pr-10">
						<template v-if="pageLoading">
							<div style="height: 226px; width: 400px" class="d-flex align-center justify-center">
								<v-progress-circular indeterminate size="60" width="6" color="cyan"></v-progress-circular>
							</div>
						</template>
						<template v-else>
							<apexchart type="pie" :options="getChartOptions" :series="getSeries" width="400"></apexchart>
						</template>
					</div>
					<div class="flex-grow-1" style="max-width: 300px">
						<div
							class="border-bottom-dotted py-1 px-1"
							v-for="(row, index) in getSourceList"
							:key="index"
						>
							<div class="fw-500 fs-16 d-flex align-center text-right">
								<v-spacer></v-spacer>
								<span :class="`${row.color}--texttt text-truncate`">
									{{ row.name }}
								</span>
								<div style="min-width: 50px">
									<v-chip
										style="min-width: 32px; min-height: 32px"
										medium
										class="pa-1 d-inline-flex justify-center"
										:color="row.color"
									>
										<template v-if="pageLoading">
											<v-progress-circular
												indeterminate
												size="14"
												width="2"
												color="white"
											></v-progress-circular>
										</template>
										<template v-else>
											<span class="fs-18 white--text">
												{{ row.total }}
											</span>
										</template>
									</v-chip>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import { GET_CLOSED_LEADS, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
import { filter } from "lodash";
export default {
	name: "ClosedLeadSourceStatus",
	data() {
		return {
			pageLoading: false,
			filterStatusList: [
				{ text: "This Month", value: "this_month" },
				{ text: "Last Month", value: "last_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "Last Quarter", value: "last_quarter" },
				{ text: "This Year", value: "this_year" },
				{ text: "Last Year", value: "last_year" },
			],
			filterProductTypeList: [
				{ text: "All Type", value: "all" },
				{ text: "Software", value: "software" },
				{ text: "Seo", value: "seo" },
			],
			graphs: [
				{
					color: "orange",
					name: "Web Enquiry",
					key: "web_enquiry_count",
				},
				{
					color: "cyan",
					name: "Web Chat",
					key: "web_chat_count",
				},
				{
					color: "brown",
					name: "Email Marketer",
					key: "email_marketer_count",
				},
				{
					color: "light-blue",
					name: "Tele Call",
					key: "tele_call_count",
				},
				{
					color: "red",
					name: "Google Adwords",
					key: "google_adwords_count",
				},
				{
					color: "pink",
					name: "FSM/AMS (Brochure Lead Form)",
					key: "fsm_ams_count",
				},
				{
					color: "purple",
					name: "Facebook",
					key: "facebook_count",
				},
				{
					color: "amber",
					name: "Referral",
					key: "referral_count",
				},
				{
					color: "blue-grey",
					name: "Intern",
					key: "intern_count",
				},
				{
					color: "amber",
					name: "Canvassing",
					key: "canvassing_team_count",
				},
				{
					color: "grey",
					name: "None",
					key: "none_count",
				},
			],
		};
	},
	methods: {
		hexColor(color) {
			switch (color) {
				case "red":
					return "#F44336";
				case "orange":
					return "#FF9800";
				case "cyan":
					return "#00BCD4";
				case "brown":
					return "#795548";
				case "light-blue":
					return "#03A9F4";
				case "teal":
					return "#009688";
				case "green":
					return "#4CAF50";
				case "blue-grey":
					return "#607D8B";
				case "indigo":
					return "#3F51B5";
				case "amber":
					return "#FFC107";
				case "purple":
					return "#9C27B0";
				case "pink":
					return "#E91E63";
				case "grey":
					return "#e0e0e0";
			}
		},
		filterByDuration(item) {
			if (this.dActiveClosedLeadDuration.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_closed_lead_duration",
					value: item,
				});
				this.getClosedLeads();
			}
		},
		filterByType(item) {
			if (this.dActiveClosedLeadType.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_closed_lead_type",
					value: item,
				});
				this.getClosedLeads();
			}
		},
		filterByUser(item) {
			if (this.dActiveClosedLeadUser.id != item.id) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_closed_lead_user", value: item });
				this.getClosedLeads();
			}
		},
		/* filterByUser(item) {
			if (this.dActiveMeetingUser.id != item.id) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_meeting_user", value: item });
				this.getMeetings();
			}
		}, */
		getClosedLeads() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET_CLOSED_LEADS, {
					filter: this.dActiveClosedLeadDuration.value,
					product_type: this.dActiveClosedLeadType.value,
					user_filter: this.dActiveClosedLeadUser.id ? this.dActiveClosedLeadUser.id : null,
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters([
			"localDB",
			"dClosedLeads",
			"dActiveClosedLeadDuration",
			"dActiveClosedLeadType",
			"dActiveClosedLeadUser",
		]),
		usersList() {
			let _users = this.localDB("allUsers", []);
			_users = filter(_users, (user) => user.users_type == "sales");
			_users.unshift({
				id: 0,
				display_name: "All Users",
				profile_img: this.$assetURL("media/users/blank.png"),
				total_project: 0,
			});
			return _users;
		},
		getSourceList() {
			const _closed_leads = this.dClosedLeads;
			return this.graphs.map((row) => {
				return {
					...row,
					total: _closed_leads[row.key] ? _closed_leads[row.key] : 0,
				};
			});
		},
		getSeries() {
			const _closed_leads = this.dClosedLeads;
			return this.graphs.map((row) => {
				return _closed_leads[row.key] ? _closed_leads[row.key] : 0;
			});
		},
		getTotalLead() {
			const _closed_leads = this.dClosedLeads;
			return _closed_leads.allLeads ? _closed_leads.allLeads : 0;
		},
		getTotalCost() {
			const _closed_leads = this.dClosedLeads;
			return _closed_leads.leads_cost ? _closed_leads.leads_cost : 0;
		},
		getChartOptions() {
			const _labels = this.graphs.map((row) => {
				return row.name;
			});
			const _colors = this.graphs.map((row) => this.hexColor(row.color));
			return {
				chart: {
					type: "pie",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: _colors,
				labels: _labels,
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.total-lead-count {
	height: 75px;
	width: 75px;
	border: 3px solid #fbd4da;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.sale-symbol {
	height: 250px;
	width: 250px;
	position: absolute;
	bottom: -30px;
	left: -30px;
	opacity: 0.1;
}
.sale2-symbol {
	height: 250px;
	width: 250px;
	position: absolute;
	top: 0px;
	left: 30px;
	opacity: 0.05;
}
</style>
